.download-container {
  background-color: #fdf8f0;
  .download-title-container {
    background-image: url("../../images/faqs-title-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0px 100px 0px;
    .title {
      display: flex;
      justify-content: center;
      font-size: 30px;
      font-weight: 800;
      color: black;
    }
  }

  .download-desc-container {
    margin-top: 40px;

    .logo-name {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .logo-desc {
      color: #b0b0b0;
      font-weight: 600;
    }

    .button-download-android {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      .ant-btn {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-weight: 600;
        background-color: #e5bd79;
        width: 100%;
      }
    }
  }
}
