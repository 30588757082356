@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:wght@1;500&display=swap");

.home-container {
  background-color: #f7fcfe;
}
.home-first-page {
  background-image: url("../../images/background-home.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  .home-first-page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-top: 40px;
    color: black;
    font-weight: 700;
  }

  .home-first-page-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-top: 10px;
    color: black;
    font-weight: 500;
  }

  .home-first-page-card {
    margin-top: 40px;
    margin-bottom: 80px;
    .ant-card {
      border-radius: 10px;
    }

    .ant-card-body {
      background: linear-gradient(#ffe3b3, #fff);
      padding: 2rem;
      border-radius: 10px;
    }

    .card-title {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      font-size: 1.2rem;
      font-family: "Sofia Sans Condensed", sans-serif;
      word-spacing: 5px;

      span {
        color: #e5bd79;
        border-bottom: 1px solid #e5bd79;
        word-spacing: 0;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .card-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 70px;

      .desc-container {
        width: calc(100% / 3);
        display: flex;
        text-align: start;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;

        .title {
          color: #e5bd79;
          font-weight: bold;
          font-size: 1.3rem;
        }
        .desc {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      .desc-container:not(:last-child)::after {
        background-color: gray;
        content: "";
        height: 32px;
        position: absolute;
        right: 0;
        top: calc(50% - 16px);
        width: 1px;
      }
    }

    .card-button {
      display: flex;
      justify-content: center;
      align-items: center;
      .apply-button {
        position: absolute;
        top: 92%;
        height: 18%;
        border-radius: 24px;
        width: 25%;
        background-color: #e5bd79;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.5s;
        box-shadow: 0 4px #999;
      }

      .apply-button:hover {
        color: black;
        background-color: #ffe3b3;
      }

      .apply-button:active {
        transform: translateY(8px);
        box-shadow: 0 2px #999;
      }
    }
  }
}

.home-second-page {
  background-image: url("../../images/background-effort-steps.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;

  .home-second-page-title {
    display: flex;
    justify-content: center;

    p {
      text-transform: capitalize;
      margin-top: 30px;
      font-size: 1.5rem;
      font-weight: bold;
      color: #e5bd79;
    }
  }

  .home-second-page-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    .image-step {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .step-divider {
      display: flex;
      justify-content: center;
      height: 60%;
      background-image: url("../../images/step-divider.png");
      background-position: center;
      background-repeat: no-repeat;
      img {
        display: flex;
        justify-content: center;
      }
    }

    .title {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 600;
      color: #e5bd79;
    }

    .desc {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
}

.home-third-page {
  background-color: #fdf8f0;
  .home-third-page-title {
    display: flex;
    justify-content: center;

    p {
      text-transform: capitalize;
      margin-top: 30px;
      font-size: 1.5rem;
      font-weight: bold;
      color: #e5bd79;
    }
  }

  .home-third-page-desc {
    padding-bottom: 80px;

    .phone-home {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    .upfront-simple-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 40px;

      .why-us-image {
        display: flex;
        justify-content: end;
      }

      .why-us-desc {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
          color: #8b8b8b;
          font-weight: 700;
        }
        .desc {
          color: #8b8b8b;
          font-weight: 500;
        }
      }
    }

    .zero-stop-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 40px;

      .why-us-image {
        display: flex;
        justify-content: start;
        align-items: start;
      }

      .why-us-desc {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
          color: #8b8b8b;
          font-weight: 700;
        }
        .desc {
          color: #8b8b8b;
          font-weight: 500;
        }
      }
    }
  }
}

.home-forth-page {
  background-image: url("../../images/background-effort-steps.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;

  .forth-page-title {
    display: flex;
    justify-content: center;
    p {
      text-transform: capitalize;
      margin-top: 30px;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 0;
      color: #e5bd79;
    }
  }

  .forth-page-desc {
    display: flex;
    justify-content: center;
    p {
      line-height: 0;
      text-transform: capitalize;
      font-weight: bold;
      color: #8b8b8b;
    }
  }

  .customer-feedback-container {
    display: flex !important;
    flex-direction: column;
  }
}

.fifth-page-container {
  background: linear-gradient(#fdf8f0, #e5bd79);

  .background-fifth-page {
    .ant-card-body {
      padding: 0;
    }
  }

  .fifth-page-image {
    background-image: url("../../images/home-background-fifth.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .fifth-page-title {
    p {
      text-transform: capitalize;
      margin-top: 30px;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 0;
      color: #e5bd79;
    }
  }

  .fifth-page-desc {
    p {
      text-align: start;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .qr-google-container {
    padding: 20px;
    display: flex;
    gap: 20px;

    .download {
      width: calc(100% / 2);

      img {
        width: 100%;
      }
    }

    p {
      color: #8b8b8b;
      font-weight: 700;
      text-align: center;
    }

    img {
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .home-first-page {
    background-image: url("../../images/background-home.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 80px;
    .home-first-page-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      margin-top: 40px;
      color: white;
      font-weight: 700;
    }

    .home-first-page-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      margin-top: 10px;
      color: white;
      font-weight: 500;
    }

    .home-first-page-card {
      margin-top: 40px;

      .ant-card {
        border-radius: 10px;
      }

      .ant-card-body {
        background: linear-gradient(#d7eff5, #fff);
        padding: 2rem;
        border-radius: 10px;
      }

      .card-title {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-family: "Sofia Sans Condensed", sans-serif;
        word-spacing: 5px;

        span {
          color: #e5bd79;
          border-bottom: 1px solid #e5bd79;
          word-spacing: 0;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: bold;
        }
      }

      .card-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 70px;

        .desc-container {
          width: calc(100% / 3);
          display: flex;
          text-align: start;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          box-sizing: border-box;

          .title {
            color: #e5bd79;
            font-weight: bold;
            font-size: 1.3rem;
          }
          .desc {
            font-size: 0.9rem;
            font-weight: 500;
          }
        }

        .desc-container:not(:last-child)::after {
          background-color: gray;
          content: "";
          height: 32px;
          position: absolute;
          right: 0;
          top: calc(50% - 16px);
          width: 1px;
        }
      }

      .card-button {
        display: flex;
        justify-content: center;
        align-items: center;
        .apply-button {
          position: absolute;
          top: 92%;
          height: 18%;
          border-radius: 24px;
          width: 45%;
          background-color: #e5bd79;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.5s;
        }

        .apply-button:hover {
          color: black;
          background-color: #d7eff5;
        }
      }
    }
  }

  .home-second-page {
    background-image: url("../../images/background-effort-steps.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;

    .home-second-page-title {
      display: flex;
      justify-content: center;

      p {
        text-transform: capitalize;
        margin-top: 30px;
        font-size: 1.5rem;
        font-weight: bold;
        color: #e5bd79;
      }
    }

    .home-second-page-desc {
      display: flex;
      justify-content: start;
      align-items: start;
      padding-bottom: 80px;
      .image-step {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        height: 100%;
        img {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .step-by-step {
        padding: 20px;
        margin-left: 40px;
      }

      .step-divider {
        display: flex;
        justify-content: center;
        height: 60%;
        background-image: url("../../images/step-divider.png");
        background-position: center;
        background-repeat: no-repeat;
        img {
          display: flex;
          justify-content: center;
        }
      }

      .title {
        margin-top: 10px;
        display: flex;
        justify-content: start;
        align-items: start;
        font-size: 1.4rem;
        font-weight: 600;
        color: #e5bd79;
      }

      .desc {
        margin-top: 10px;
        display: flex;
        justify-content: start;
        align-items: start;
        text-align: start;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }

  .home-third-page {
    background-color: #fdf8f0;
    .home-third-page-title {
      display: flex;
      justify-content: center;

      p {
        text-transform: capitalize;
        margin-top: 30px;
        font-size: 1.5rem;
        font-weight: bold;
        color: #e5bd79;
      }
    }

    .home-third-page-desc {
      padding-bottom: 80px;

      .phone-home {
        display: flex;
        justify-content: center;
      }

      .upfront-simple-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        gap: 40px;

        .why-us-image {
          display: flex;
          justify-content: center;
        }

        .why-us-desc {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .title {
            color: #8b8b8b;
            font-weight: 700;
          }
          .desc {
            color: #8b8b8b;
            font-weight: 500;
          }
        }
      }

      .zero-stop-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        gap: 40px;

        .why-us-image {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .why-us-desc {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .title {
            color: #8b8b8b;
            font-weight: 700;
          }
          .desc {
            color: #8b8b8b;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) and (max-height: 500px) {
}
