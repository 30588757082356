.footer-container {
  background-color: black;
  //background-image: url("../../images/home-background-footer.png");
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: 10px;

  .footer-first-container {
    margin-top: 20px;
    .logo-container {
      margin-bottom: 20px;
      img {
        width: 70%;
      }
    }
  }
  p {
    line-height: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #e5bd79;
    cursor: pointer;
  }

  span {
    font-size: 15px;
    font-weight: 700;
    color:#e5bd79;
  }

  .copy-right-container {
    margin-top: 10px;

    div {
      font-size: 13px;
      color:#e5bd79;
      font-weight: bold;
    }
  }
}
